import { DepositRequestStatus } from "./DepositRequest";
import { VAccountPartner } from "./VAccountPartner";

export class TabPayment {
    constructor(
        public id: string,
        public merchantId: string,
        public merchantName: string,
        public createdTime: Date,
        public accountId: string,
        public transactionId: string,
        public requestId: string,
        public type: string,
        public status: DepositRequestStatus,
        public amount: number,
        public bankId: string,
        public address: string,
        public addressName: string,
        public networkId: string,
        public networkReference: string,
        public networCode: string,
        public account: VAccountPartner,
    ){}
}