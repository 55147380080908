import { Country, PartnerCurrency } from './'

export class Currency {
  constructor(
    public id: string,
    public name: string,
    public symbol: string,
    public position: number,
    public visible: true,
    public precision: number,
    public iconUrl: string,
    public countries?: Country[],
    public attributes?: PartnerCurrency,
  ) {}
}
