/**
 * Role / User grioup model
 */
export class Role {
  constructor(public id: string, public createdTime: Date, public name: string, public realm: string, public resources: RoleResource[]) {}
}

export type RoleResource = {
  resource: string
  scopes: string[]
}
