import Api from '../../utils/Api'
import { User, Role } from '../../models'
import { Reducer } from 'react'
import { AsyncActionHandlers } from '../../utils/useReducerAsync'

export type SessionType = {
  loading: boolean
  accessToken: string | null
  user: User | null
  role: Role | null
}

export type SessionAsyncActionType = { type: 'FETCH_USER_ME' }

export type SessionActionType =
  | { type: 'LOADING'; loading: boolean }
  | { type: 'SET_ACCESS_TOKEN'; token: string }
  | { type: 'SET_USER'; user: User | null }
  | { type: 'SIGNOUT' }
  | SessionAsyncActionType

export const sessionReducer: Reducer<SessionType, SessionActionType> = (state: SessionType, action: SessionActionType) => {
  // return new Promise((resolve) => {
  // resolve(state)
  switch (action.type) {
    case 'LOADING': {
      return { ...state, loading: action.loading }
    }

    case 'SET_ACCESS_TOKEN':
      if (action.token) {
        Api.defaults.headers.common['Authorization'] = 'Bearer ' + action.token
      } else {
        delete Api.defaults.headers.common['Authorization']
      }
      return { ...state, accessToken: action.token }

    case 'SET_USER': {
      let user: User | null = null
      if (action.user) user = User.create(action.user)
      return { ...state, user }
    }

    case 'SIGNOUT': {
      delete Api.defaults.headers.common['Authorization']
      return { loading: false, accessToken: null, user: null, role: null }
    }

    default:
      return state
  }
  // })
}

export const sessionActionHandlers: AsyncActionHandlers<Reducer<SessionType, SessionActionType>, SessionAsyncActionType> = {
  FETCH_USER_ME:
    ({ dispatch, getState }) =>
    async action => {
      if (!getState().user) dispatch({ type: 'LOADING', loading: true })
      Api.request({
        method: 'GET',
        url: '/users/me',
      })
        .then(({ data }) => {
          dispatch({ type: 'SET_USER', user: data })
          if (getState().loading || !getState().user) dispatch({ type: 'LOADING', loading: false })
        })
        .catch(err => {
          dispatch({ type: 'SET_USER', user: null })
          if (err && err.response && err.response.status === 401) {
            delete Api.defaults.headers.common['Authorization']
            sessionStorage.clear()
          }

          if (err && err.response && (err.response.status === 401 || err.response.status === 404) && window.location.pathname.indexOf('/auth') !== 0) {
            window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
          } else if (window.location.pathname.indexOf('/auth') === 0) {
            if (getState().loading) dispatch({ type: 'LOADING', loading: false })
          }
        })
    },
}
