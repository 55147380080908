import { Withdraw } from './Withdraw'

export enum ESuspectTransactionStatus {
  SUSPECTED = 'suspected',
  DECLINED = 'declined',
  BLOCKED = 'blocked',
  CLARIFICATION = 'clarification',
  HIGHRISK = 'highrisk',
  WHITELIST = 'whitelist'
}

export class SuspectTransaction {
  constructor(
    public id: string,
    public withdrawId: string,
    public createdTime: Date,
    public updatedTime: Date,
    public status: ESuspectTransactionStatus,
    public additionalData: any,
    public withdraw?: Withdraw,
  ) {}
}
