import { Account, Quote } from './'

export class Transfer {
  constructor(
    public id: string,
    public createdTime: Date,
    public senderAccountId: string,
    public receiverAccountId: string,
    public quoteId: string,
    public senderAccount?: Account,
    public receiverAccount?: Account,
    public quote?: Quote,
  ) {}
}
