import { Box, makeStyles, Paper, Typography } from '@material-ui/core'
import classNames from 'classnames'
import React, { forwardRef, Ref } from 'react'

import { Helmet } from 'react-helmet-async'
import { useTranslation } from 'react-i18next'

type PageProps = {
  title?: string
  ref?: Ref<any>
  padded?: boolean
  className?: string
  [k: string]: any
}

const Page: React.FunctionComponent<PageProps> = forwardRef(({ children, title = '', padded, className, ...rest }, ref) => {
  const classes = useStyles()
  const { t } = useTranslation()

  return (
    <div ref={ref} className={classNames(classes.root, padded && classes.rootPadded, className)} {...rest}>
      <Helmet>
        <title>{title}</title>
      </Helmet>
      {children}
      <Paper>
        <Box className={classes.footerContainer} p={2}>
          <Box>
            <Typography variant="caption" color="initial">
              {t('footer.copyright', { year: new Date().getFullYear() })}
            </Typography>
          </Box>
          <Box className={classes.btnFooterContainer}>
            <a href="https://www.pazemo.com/terms-and-condition" rel="noopener noreferrer" target="_blank">
              <Typography variant="caption" color="initial">
                {t('footer.tnc')}
              </Typography>
            </a>
            <a href="https://www.pazemo.com/privacy-policy" rel="noopener noreferrer" target="_blank">
              <Typography variant="caption" color="initial">
                {t('footer.privacyPolicy')}
              </Typography>
            </a>
          </Box>
        </Box>
      </Paper>
    </div>
  )
})

const useStyles = makeStyles(theme => ({
  root: {
    width: '100%',
    minHeight: '100%',
    // minHeight: 600
  },
  rootPadded: {
    padding: theme.spacing(1),
  },
  footerContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 8,
    justifyContent: 'space-between',
  },
  btnFooterContainer: {
    display: 'flex',
    flexDirection: 'row',
    gap: 16,
  },
}))
export default Page
