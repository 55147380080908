/**
 *
 * App
 */

import LoadingScreen from 'components/LoadingScreen'
import { AppProvider } from 'contexts/AppContext'
import dayjs from 'dayjs'
import React, { PropsWithChildren, useContext } from 'react'
import { Helmet, HelmetProvider } from 'react-helmet-async'
import { BrowserRouter, Route, Switch } from 'react-router-dom'

import { ConfirmPage } from 'containers/auth/ConfirmPage'
import { SignoutPage } from 'containers/auth/SignoutPage'
import { AdminLayout } from 'containers/layout/AdminLayout'
import { ResourceProvider } from 'contexts'
import { SessionContext, SessionProvider } from 'contexts/SessionContext'
import { GlobalStyle } from 'styles/global-styles'
import { ThemeProvider } from 'styles/theme/ThemeProvider'
import { LoginPage } from './containers/auth/LoginPage'

// eslint-disable-next-line @typescript-eslint/no-var-requires
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(localizedFormat)

export default function App() {
  return (
    <ThemeProvider>
      <HelmetProvider>
        <SessionProvider>
          <ResourceProvider>
            <AppProvider>
              <React.Suspense fallback={<LoadingScreen />}>
                <BrowserRouter>
                  <Helmet titleTemplate="%s - App" defaultTitle="Multi assets">
                    <meta name="description" content="App platform remittance by 5Elements" />
                  </Helmet>
                  <Switch>
                    <Route path="/auth">
                      <Route path="/auth/signin" component={LoginPage} />
                      <Route path="/auth/signout" component={SignoutPage} />
                      <Route path="/auth/confirm" component={ConfirmPage} />
                    </Route>
                    <PrivateRoute>
                      <Route path={'/admin'} component={AdminLayout}></Route>
                    </PrivateRoute>
                  </Switch>
                  <GlobalStyle />
                </BrowserRouter>
              </React.Suspense>
            </AppProvider>
          </ResourceProvider>
        </SessionProvider>
      </HelmetProvider>
    </ThemeProvider>
  )
}

const PrivateRoute: React.FC<{ children: PropsWithChildren<any> }> = ({ children }) => {
  const [session] = useContext(SessionContext)
  const isLoggedIn = session.user ? true : false

  return isLoggedIn ? children : null
}
