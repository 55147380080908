import { Partner } from './Partner'

export enum FeeTypes {
  Deposit = 'deposit',
  Exchange = 'exchange',
  Transfer = 'transfer',
  Withdraw = 'withdraw',
  Rate = 'rate',
}

export enum FeeSide {
  Debitor = 'debitor',
  Creditor = 'creditor',
}

export class Fee {
  constructor(
    public id: number,
    public partnerId: string,
    public createdTime: Date,
    public type: FeeTypes,
    public currencyId: string,
    public toCurrencyId: string,
    public name: string,
    public amount: number,
    public amountType: string,
    public side: FeeSide,
    public partner?: Partner,
    public bankId?: string,
    public channel?: string,
  ) {}
}
