import { Box, Button, FormControl, Grid, Hidden, IconButton, InputAdornment, Paper, TextField, Theme, Typography, alpha, makeStyles } from '@material-ui/core'
import { ArrowForward, Visibility, VisibilityOff } from '@material-ui/icons'
import Alert from '@material-ui/lab/Alert'
import classNames from 'classnames'
import { ContentDivider } from 'components/Divider'
import Logo from 'components/Logo'
import Page from 'components/Page'
import { SessionContext } from 'contexts/SessionContext'
import { User } from 'models/User'
import React, { Fragment, useCallback, useContext, useEffect, useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'
import { useTranslation } from 'react-i18next'
import { NavLink, useHistory } from 'react-router-dom'
import Api from 'utils/Api'
import Env from 'utils/Env'

export const LoginPage: React.FunctionComponent<{}> = () => {
  const [session, sessionDispatch] = useContext(SessionContext)
  const { t } = useTranslation()
  const classes = useLoginStyles()

  useEffect(() => {}, [])

  return (
    <Page title="Sign In">
      <div className={classes.container}>
        <Hidden smDown>
          <div className={classes.header}>
            <p className={classes.headerLogo}>
              <Logo
                size="large"
                // variant={Math.random() < 0.5 ? 'dark' : 'default'}
                variant={'dark'}
              />
              <h1 className={classes.headerTagline}>{t('app.tagline')}</h1>
            </p>
          </div>
        </Hidden>
        <div className={classes.wrapper}>
          <LoginForm
            onLoginSucceed={() => {
              // login(values)
            }}
          />
        </div>
      </div>
    </Page>
  )
}

const LoginForm: React.FunctionComponent<{
  onLoginSucceed: () => void
}> = ({ onLoginSucceed }) => {
  const [session, sessionDispatch] = useContext(SessionContext)
  const { t } = useTranslation()
  const history = useHistory()
  const classes = useLoginStyles()

  const [username, setUsername] = useState<string>()
  const [password, setPassword] = useState<string>()
  const [showPassword, setShowPassword] = useState<boolean>(false)
  const [error, setError] = useState<string>()
  const [token, setToken] = useState<string>()

  const login = useCallback((username, password, token) => {
    return Api.request<{ id: string; token: string }>({
      method: 'POST',
      url: '/users/login',
      data: {
        email: username,
        password,
        token: token ?? 'xxxxxx',
      },
    })
      .then(({ data }) => {
        if (!data) return

        sessionDispatch({ type: 'SET_ACCESS_TOKEN', token: data.token })

        Api.request<User>({
          method: 'GET',
          url: '/users/me',
        }).then(({ data }) => {
          // dispatch({ type: 'SET_ACCESS_TOKEN', payload: data.token })
          sessionDispatch({ type: 'SET_USER', user: data })
        })
      })
      .catch(err => {
        if (err?.response?.data?.error?.message) {
          setError(err?.response?.data?.error?.message)
          return
        }
        setError('Access forbidden')
      })
  }, [])

  React.useEffect(() => {
    if (session.user) {
      history.replace('/admin')
      return
    }
  }, [session.user])

  const handleClickShowPassword = useCallback(() => {
    setShowPassword(!showPassword)
  }, [showPassword])

  const handleSubmit = useCallback(
    async (e: any) => {
      e.preventDefault()
      if (Env.recaptchaKey && !token) {
        setError(t('errors.captcha_required'))
        return
      }
      setError(undefined)

      login(username, password, token).then(() => {
        onLoginSucceed()
      })
    },
    [username, password, token],
  )

  const onRecaptcha = (token: string | null) => {
    setToken(token || undefined)
  }

  return (
    <Fragment>
      <Hidden mdUp>
        <Box ml={2}>
          <Logo variant="dark" />
        </Box>
      </Hidden>

      <Paper className={classNames(classes.paperWrap, classes.petal)}>
        <Hidden smDown>
          <div className={classes.topBar}>
            <Grid container justifyContent="space-between">
              <Grid item>
                <NavLink to="/admin" className={classes.brand}>
                  <Logo />

                  {/* {t('login.title')} */}
                </NavLink>
              </Grid>
            </Grid>
          </div>
        </Hidden>

        <Box marginY={5}>
          <Typography variant="h4" className={classes.title} gutterBottom>
            {t('login.title')}
          </Typography>
        </Box>
        <section className={classes.socmedSideLogin}>
          <div className={classes.btnArea}></div>
          <ContentDivider content={t('login.sign_in_email')} />
        </section>
        <section className={classes.formWrap}>
          <form onSubmit={handleSubmit}>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  name="username"
                  placeholder={t('login.email')}
                  autoComplete="off"
                  label={t('login.email')}
                  required
                  className={classes.field}
                  // validate={[required, email]}
                  // value={username}
                  onChange={e => {
                    setUsername(e.target.value)
                  }}
                />
              </FormControl>
            </div>
            <div>
              <FormControl className={classes.formControl}>
                <TextField
                  variant="outlined"
                  name="password"
                  type={showPassword ? 'text' : 'password'}
                  label={t('login.password')}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          aria-label="Toggle password visibility"
                          onClick={handleClickShowPassword}
                          // onMouseDown={handleMouseDownPassword}
                        >
                          {showPassword ? <VisibilityOff /> : <Visibility />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                  required
                  // validate={required}
                  className={classes.field}
                  onChange={e => {
                    setPassword(e.target.value)
                  }}
                />
              </FormControl>
            </div>

            {error ? <Alert severity="error">{error}</Alert> : null}

            {/* <div className={classes.optArea}>
              <Box></Box>
              <Button
                size="small"
                // to="/reset-password"
                className={classes.buttonLink}
              >
                {t('login.forgot_password')}
              </Button>
            </div> */}
            {Env.recaptchaKey && (
              <Box marginY={2} display="flex" justifyContent={'center'}>
                <ReCAPTCHA sitekey={Env.recaptchaKey} onChange={onRecaptcha} size="normal" />
              </Box>
            )}

            <div className={classes.btnArea}>
              <Button variant="contained" color="primary" size="large" type="submit">
                {t('login.sign_in')}
                <ArrowForward
                  className={classNames(classes.rightIcon, classes.iconSmall)}
                  // disabled={false}
                />
              </Button>
            </div>
          </form>
        </section>
      </Paper>
    </Fragment>
  )
}

const wrapper = (theme: Theme, opacity: number) => ({
  padding: theme.spacing(3),
  backgroundColor: alpha(theme.palette.background.paper, opacity),
  backgroundRepeat: 'no-repeat',
  color: theme.palette.text.primary,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  backgroundAttachment: 'fixed',
  // textAlign: 'center',
})

export const useLoginStyles = makeStyles(theme => ({
  root: {
    display: 'flex',
    alignItems: 'stretch',
    // position: 'absolute',
    // top: 0,
    // left: 0,
    // right: 0,
    // bottom: 0,
    // flex: 1,
    // flexGrow: 1,
    minHeight: '100%',
  },
  container: {
    flex: 1,
    display: 'flex',
    alignItems: 'center',
    minHeight: '100%',
    height: '100%',
    // background: `linear-gradient(-45deg, #2196F3 0%, #2196F3 33%, #00BFA5 100%)`
    background: `linear-gradient(-60deg, #00BFA5 0%, #2196F3 73%, #2196F3 100%)`,
  },
  header: {
    color: '#fff',
    justifyContent: 'center',
    flex: 1,
    padding: 50,
  },
  headerLogo: {
    // marginLeft: 60,
    marginTop: 180,
    marginBottom: 180,
  },
  headerLogoImg: {
    width: 260,
  },
  headerTitle: {
    fontSize: 48,
    // lineHeight: 48,
  },
  headerTagline: {
    fontSize: 32,
    marginTop: '2rem',
    marginLeft: 0,
  },
  headerSubtitle: {
    fontSize: 32,
    marginTop: 15,
    marginBottom: 15,
  },
  headerDesc: {
    marginTop: 15,
    fontSize: 18,
  },
  wrapper: {
    minWidth: 320,
    height: '100%',
    [theme.breakpoints.down('md')]: {
      flex: 1,
    },
  },
  topBar: {
    display: 'flex',
    justifyContent: 'space-between',
    '& $icon': {
      marginRight: theme.spacing(1),
    },
    [theme.breakpoints.down('sm')]: {
      justifyContent: 'center',
      marginBottom: theme.spacing(3),
      '& a': {
        display: 'none',
      },
    },
  },

  socmedSideLogin: {
    padding: '16 16px 1px',
    margin: '0 auto',
    '& button': {
      padding: '4px 16px',
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.only('sm')]: {
      width: 480,
    },
  },
  brand: {
    display: 'flex',
    alignItems: 'center',
    // justifyContent: 'center',
    // padding: '5px 10px',
    position: 'relative',
    fontSize: 16,
    fontWeight: 500,
    color: theme.palette.text.primary,
    textDecoration: 'none',
    '&$outer': {
      color: theme.palette.common.white,
    },
    [theme.breakpoints.down('md')]: {
      margin: theme.spacing(2),
    },
    '& img': {
      width: 30,
      marginRight: 10,
    },
  },
  outer: {},
  paperWrap: {
    ...wrapper(theme, 1),
    height: '100%',
    borderRadius: 0,
    [theme.breakpoints.down('md')]: {
      padding: 10,
    },
    [theme.breakpoints.up('lg')]: {
      width: 560,
    },
    '& $topBar': {
      marginBottom: theme.spacing(4),
    },
    textAlign: 'center',
  },
  petal: {},
  formControl: {
    width: '100%',
    marginTop: theme.spacing(1),
    marginBottom: theme.spacing(1),
  },
  btnArea: {
    display: 'flex',
    justifyContent: 'space-around',
    margin: `${theme.spacing(2)}px 0`,
    fontSize: 12,
    '& $label': {
      fontSize: 12,
      '& span': {
        fontSize: 12,
      },
    },
    '& button': {
      margin: `0 ${theme.spacing(1)}px`,
    },
    [theme.breakpoints.down('xs')]: {
      flexDirection: 'column',
      '& button': {
        width: '100%',
        margin: 5,
      },
    },
  },
  buttonLink: {
    background: 'none',
    padding: 0,
    textTransform: 'none',
    transition: 'color ease 0.3s',
    fontWeight: theme.typography.fontWeightRegular,
    textDecoration: 'none',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '0.875rem',
    '&:hover': {
      background: 'none',
      color: theme.palette.secondary.main,
    },
  },
  leftIcon: {
    marginRight: theme.spacing(1),
  },
  rightIcon: {
    marginLeft: theme.spacing(1),
  },
  iconSmall: {
    fontSize: 20,
  },
  icon: {},
  optArea: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: `0 ${theme.spacing(0.5)}px`,
  },
  title: {
    color: theme.palette.primary.main,
  },
  subtitle: {
    fontSize: 14,
  },
  formWrap: {
    margin: '0 auto',
    [theme.breakpoints.only('sm')]: {
      width: 480,
    },
  },
  field: {},
  label: {},
}))
