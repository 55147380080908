export class Settlement {
  constructor(
    public settlementId: string,
    public name: string,
    public merchantId: string,
    public accountId: string,
    public partnerId: string,
    public partnerCode: string,
    public hasSettlement: boolean,
    public address: string,
    public addressName: string,
    public networkId: string,
    public bankId: string,
    public bankName: string,
    public currencyId: string,
    public accountNumber: string,
    public dates: string,
    public timeSegment: string,
    public settlementDates: string,
    public releaseDates: string,
    public total: string,
    public amount: string,
    public releaseAmount: string,
    public type: string,
    public releaseStatus: string,
  ) {}
}

export enum SettlementReleaseStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  PROCESS = 'process',
}