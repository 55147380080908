import React, { useContext } from 'react'
import { AppContext } from 'contexts/AppContext'

// eslint-disable-next-line @typescript-eslint/ban-ts-comment
//@ts-ignore
import { Eclipse } from 'react-loading-io'
import { SessionContext } from 'contexts/SessionContext'

const LoadingScreen: React.FC = () => {
  const [session] = useContext(SessionContext)

  return (
    <div style={{ position: 'relative', width: '100%', height: '100vh' }}>
      <div
        style={{
          position: 'absolute',
          top: '0',
          left: '0',
          right: '0',
          bottom: '0',
          height: '100px',
          width: '100px',
          margin: 'auto',
          textAlign: 'center',
        }}
      >
        <Eclipse size={160} style={{ margin: 10 }} color="#4CA7FD" />
        Please wait
        {session.user ? ` ${session.user.email}..` : '..'}
      </div>
    </div>
  )
}

export default LoadingScreen
