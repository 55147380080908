import React from 'react'
import { ThemeProvider as OriginalThemeProvider } from '@material-ui/core'
import { themes } from './themes'

export const ThemeProvider = (props: { children: React.ReactChild }) => {
  // useInjectReducer({ key: themeSliceKey, reducer: reducer })

  // const theme = useSelector(selectTheme)
  return <OriginalThemeProvider theme={themes.light}>{React.Children.only(props.children)}</OriginalThemeProvider>
}
