import { City } from "./City";
import { Country } from "./Country";
import { District } from "./District";
import { Province } from "./Province";

export class Address {
    constructor(
      public id?: string,
      public countryId?: string,
      public provinceId?: number,
      public cityId?: number,
      public districtId?: number,
      public postalCode?: string,
      public detail?: string,
      public country?: Country,
      public province?: Province,
      public city?: City,
      public district?: District,
    ) {}
  }