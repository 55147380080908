import React, { createContext, PropsWithChildren, useContext } from 'react'
import { StorageManager } from 'utils/StorageManager'
import { AxiosError } from 'axios'
import ApiErrorParser from 'utils/ApiErrorParser'
import { SessionContext } from './SessionContext'
import LoadingComponent from 'components/LoadingComponent'
import { toast } from 'react-toastify'

const storageManager = new StorageManager()

export type AuthorizedParams = {
  roles?: string[]
  resource?: string
  scope?: string
  scopes?: string[]
}

// let fetchTimer: number = -1

const AppContext = createContext<{
  flash: (m: string) => void
  handleError: (e: string | AxiosError) => void
  isAuthorized: (params: AuthorizedParams) => boolean
}>({
  flash: () => null,
  handleError: () => null,
  isAuthorized: () => false,
})

const AppProvider: React.FC<{
  children?: React.ReactNode
}> = ({ children }: PropsWithChildren<unknown>) => {
  // const history = useHistory()
  // const { path, url } = useRouteMatch()
  // const [loading, setLoading] = React.useState<boolean>( state.session.user === null)
  const [session, sessionDispatch] = useContext(SessionContext)

  const isAuthorized: (params: AuthorizedParams) => boolean = ({ roles, resource, scope, scopes }) => {
    let allow = false

    if (session.role && session.role.resources && resource) {
      const foundIndex = session.role.resources.findIndex(f => {
        if (f.resource === resource) {
          if (scope && !scopes) {
            scopes = [scope]
          }

          if (scopes && scopes.length > 0) {
            // if(f.scopes.in)
            let fulfilled = true
            scopes.forEach(s => {
              if (!f.scopes.includes(s)) {
                fulfilled = false
              }
            })
            return fulfilled
          }
        }
        return false
      })

      allow = foundIndex !== -1
    } else if (roles) {
      roles.forEach(role => {
        if (session.user?.hasRole(role)) {
          allow = true
        }
      })
    }
    return allow
  }

  const createFlash = (s: string) => {
    toast.info(s)
  }

  const handleError = (e: string | AxiosError) => {
    toast.error(new ApiErrorParser(e).toString())
  }

  return (
    <AppContext.Provider value={{ flash: createFlash, handleError, isAuthorized }}>{session.loading ? <LoadingComponent /> : children}</AppContext.Provider>
  )
}

export { AppProvider, AppContext }
