import React from 'react'
// @ts-ignore
import { Eclipse } from 'react-loading-io'
import { Box, BoxProps, Typography } from '@material-ui/core'

type LoadingComponentProps = {
  message?: string
  size?: number
}

const LoadingComponent: React.FC<BoxProps & LoadingComponentProps> = ({ message, size = 60, ...props }) => (
  <Box textAlign="center" style={{}} {...props}>
    <Eclipse size={size} style={{ margin: 0 }} color="#4CA7FD" speed={2} />
    {message ? (
      <Box marginTop={2}>
        <Typography variant="body1" color="textSecondary">
          {message}
        </Typography>
      </Box>
    ) : null}
  </Box>
)

export default LoadingComponent
