import { Box, Button, Grid, TextField, Typography } from '@material-ui/core'
import React, { useContext, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Check as CheckIcon } from 'react-feather'

//@ts-ignore
import QRCode from 'qrcode.react'
import Api from 'utils/Api'
import { SessionContext } from 'contexts/SessionContext'
import { toast } from 'react-toastify'
import FormatHelper from 'utils/FormatHelper'
import { SettingsBackupRestoreTwoTone } from '@material-ui/icons'
import { AxiosError } from 'axios'

interface TwoFAObject {
  url: string
  secret: string
}
export const TwoFARequestAuthentication: React.FunctionComponent<{}> = () => {
  const { t } = useTranslation()

  const [session] = useContext(SessionContext)

  const [loading, setLoading] = useState<boolean>(true)
  const [isCompleted, setIsCompleted] = useState<boolean>(false)
  const [fa, setFa] = useState<TwoFAObject>()
  const [code, setCode] = useState<string>()

  const fetchGenerate = () => {
    setLoading(true)
    Api.get<TwoFAObject>(`/users/${session.user?.id}/twofa`, {})
      .then(res => {
        if (res && res.data) {
          setFa(res.data)
        }
        setLoading(false)
      })
      .catch(err => {
        if (err instanceof Object && (err as AxiosError).response) {
          const errax = err as AxiosError
          if (errax.response?.status == 409) {
            setIsCompleted(true)
            setLoading(false)
            if (session && session.user) {
              session.user.twoFAEnabled = true
            }
            return
          }
        }
        toast.error(FormatHelper.error(err))
      })
  }

  const onSubmit = () => {
    setLoading(true)
    Api.post(`/users/${session.user?.id}/twofa`, {
      token: code,
      secret: fa?.secret,
    })
      .then(res => {
        setIsCompleted(true)
        if (session && session.user) {
          session.user.twoFAEnabled = true
        }
      })
      .catch(err => {
        toast.error(FormatHelper.error(err))
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    fetchGenerate()
  }, [])

  // useEffect(() => {
  //   const timer = setTimeout(() => {
  //     fetchGenerate()
  //   }, 15000)

  //   return () => {
  //     clearTimeout(timer)
  //   }
  // }, [fa])

  return (
    <Box maxWidth={960}>
      <Grid container justifyContent="center" spacing={2}>
        <Grid item md={6}>
          <p>
            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>1. </span>
            {t('profile.twofa_google_step1', {
              appstoreLink: '',
            })}
            <br />* for IOS:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://itunes.apple.com/ru/app/google-authenticator/id388497605?mt=8">
              App Store
            </a>
            <br />* for Android:{' '}
            <a target="_blank" rel="noopener noreferrer" href="https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2&amp;hl">
              Google play
            </a>
          </p>
          <p style={{ marginTop: 10 }}>
            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>2. </span>
            {t('profile.twofa_google_step2')}
          </p>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'center' }}>
          {isCompleted ? (
            <p>
              <CheckIcon size={64} color="green" />
              <br />
              <h4>{t('profile.twofa_google_enabled')}</h4>
            </p>
          ) : (
            <div>
              <p>{fa && fa.url ? <QRCode size={200} value={fa.url} /> : null}</p>
              <Typography style={{ color: '#ccc' }}>{fa?.secret}</Typography>
            </div>
          )}
        </Grid>
        <Grid item md={6}>
          <Box marginTop={2}>
            <span style={{ fontWeight: 'bold', fontSize: '1.2em' }}>3. </span>
            {t('profile.twofa_google_step3')}
          </Box>
        </Grid>
        <Grid item md={6} style={{ textAlign: 'center', paddingTop: 20 }}>
          {isCompleted ? null : (
            <React.Fragment>
              <Box>
                <TextField
                  variant="outlined"
                  label={t('profile.twofa_google_code')}
                  value={code}
                  required={true}
                  disabled={loading}
                  size="medium"
                  onChange={e => {
                    setCode(e.target.value)
                  }}
                  helperText={t('profile.twofa_input_code')}
                  inputProps={{ inputMode: 'numeric', pattern: '[0-9]*', maxLength: 6 }}
                />
              </Box>
              <Box marginTop={2}>
                <Button variant="contained" color="primary" onClick={onSubmit} disabled={loading || !code}>
                  {t('profile.enable_twofa')}
                </Button>
              </Box>
            </React.Fragment>
          )}
        </Grid>
      </Grid>
    </Box>
  )
}
