import { Bank, Account } from './'

export class Beneficiary {
  constructor(
    public id: number,
    public accountId: string,
    public bankId: string,
    public address: string,
    public name: string,
    public label: string,
    public status: BeneficiaryStatus,
    public type: BeneficiaryType,
    public updatedTime: Date,
    public account?: Account,
    public bank?: Bank,
  ) {}
}

export enum BeneficiaryStatus {
  Pending = 'pending',
  Inactive = 'inactive',
  Verified = 'verified',
}

export enum BeneficiaryType {
  Personal = 'personal',
  Individual = 'individual',
  Corporate = 'corporate',
}
