import { makeStyles } from '@material-ui/core'
import { SessionContext } from 'contexts/SessionContext'
import { UserRoles } from 'models'
import React, { ReactNode, useEffect, useState } from 'react'
import { Redirect, useHistory } from 'react-router-dom'
import AdminNavBar from './AdminNavBar'
import { AdminRoutes } from './AdminRoutes'
import { Decoration } from './Decoration'
import TopBar from './TopBar'

interface RouteProps {
  title: string
  href?: string
  icon?: ReactNode
  component?: ReactNode
}

export function AdminLayout() {
  const classes = useStyles()
  const history = useHistory()
  const [session] = React.useContext(SessionContext)

  const [isMobileNavOpen, setMobileNavOpen] = useState<boolean>()

  useEffect(() => {
    // nothing?
  }, [session.user])

  if (
    !(
      session.user &&
      (session.user.hasRole(UserRoles.Admin) ||
        session.user.hasRole(UserRoles.AdminFinance) ||
        session.user.hasRole(UserRoles.OperationFinance) ||
        session.user.hasRole(UserRoles.OperationSupport) ||
        session.user.hasRole(UserRoles.Compliance))
    )
  )
    return <Redirect to="/" />

  return (
    <>
      <Decoration />
      <div className={classes.root}>
        <TopBar onMobileNavOpen={() => setMobileNavOpen(true)} className={classes.topbar} />

        <AdminNavBar onMobileClose={() => setMobileNavOpen(false)} openMobile={isMobileNavOpen} />

        <div className={classes.wrapper}>
          <div className={classes.contentContainer}>
            <div className={classes.content}>
              <AdminRoutes />
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

const useStyles = makeStyles(theme => ({
  root: {
    // backgroundColor: theme.palette.background.dark,
    display: 'flex',
    height: '100%',
    overflow: 'hidden',
    width: '100%',
  },
  topbar: {
    backgroundColor: '#ef6161',
    // paddingLeft: 240,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 245,
    },
  },
  // topbarMobile: { paddingLeft: 0 },
  wrapper: {
    zIndex: 10,
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
    paddingTop: 64,
    [theme.breakpoints.up('lg')]: {
      paddingLeft: 256,
    },
  },
  contentContainer: {
    display: 'flex',
    flex: '1 1 auto',
    overflow: 'hidden',
  },
  content: {
    flex: '1 1 auto',
    height: '100%',
    overflow: 'auto',
  },
}))
