export class ReleaseBalance {
  constructor(
    public settlementId: string,
    public name: string,
    public merchantId: string,
    public accountId: string,
    public depositDate: string,
    public releaseDate: string,
    public depositAmount: string,
    public disburseAmount: string,
    public remainingBalance: string,
    public releaseAmount: string,
    public releasedFeeAmount: string,
    public releaseStatus: string,
    public type: string,
    public currencyId: string,
    public accountNumber: string,
    public partnerId: string,
    public partnerCode: string,
    public hasSettlement: string,
    public bankId: string,
    public bankName: string,
    public address: string,
    public addressName: string,
    public networkId: string,
    public total: string,
  ) {}
}

export class ReleaseBalanceDetail {
  constructor(
    public settlementId: string,
    public address: string,
    public bankName: string,
    public addressName: string,
    public releaseTime: Date,
    public depositTime: Date,
    public releaseAmount: number,
    public feeAmount: number,
    public releaseStatus: string
  ) {}
}

export enum ReleaseBalanceStatus {
  COMPLETE = 'complete',
  INCOMPLETE = 'incomplete',
  PROCESS = 'process',
}