import { Divider, LinearProgress } from '@material-ui/core'
import Paper from '@material-ui/core/Paper'
import Typography from '@material-ui/core/Typography'
import { makeStyles } from '@material-ui/core/styles'
import classNames from 'classnames'
import React, { ReactNode } from 'react'
import { Bookmark } from 'react-feather'
import styles from './paperStyle-jss'

type PaperBlockProps = {
  title: string
  desc: string | JSX.Element
  // icon?: FC<IconProps>
  icon?: ReactNode
  whiteBg?: boolean
  colorMode?: boolean
  noMargin?: boolean
  overflowX?: boolean
  ext?: ReactNode
  loading?: boolean
}

const PaperBlock: React.SFC<PaperBlockProps> = props => {
  const {
    title,
    desc,
    children,
    whiteBg = true,
    noMargin,
    colorMode,
    overflowX,
    icon,
    ext,
    loading,
    // icon = Bookmark,
  } = props
  const classes = useStyles()

  return (
    <div>
      <Paper className={classNames(classes.root, noMargin && classes.noMargin, colorMode && classes.colorMode)} elevation={0}>
        <div className={classes.headerBlock}>
          <div className={classes.descBlock}>
            {icon ? (
              <span className={classes.iconTitle} style={{ background: 'none', border: 'none', boxShadow: 'none' }}>
                {icon}
              </span>
            ) : (
              <span className={classes.iconTitle}>
                <Bookmark />
              </span>
            )}
            <div className={classes.titleText}>
              <Typography variant="h6" component="h2" className={classes.title}>
                {title}
              </Typography>
              <Typography component="p" className={classes.description}>
                {desc}
              </Typography>
            </div>
          </div>
          {ext && <div className={classes.extBlock}>{ext}</div>}
        </div>
        {loading ? <LinearProgress /> : <Divider style={{ marginTop: 3 }} />}
        <section className={classNames(classes.content, whiteBg && classes.whiteBg, overflowX && classes.overflowX)}>{children}</section>
      </Paper>
    </div>
  )
}

//@ts-ignore
const useStyles = makeStyles(styles)

export default PaperBlock
