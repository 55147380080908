import { Box, Button, FormControl, TextField } from '@material-ui/core'
import { AppContext } from 'contexts/AppContext'
import { SessionContext } from 'contexts/SessionContext'
import lodash from 'lodash'
import { User } from 'models'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import Api from 'utils/Api'

interface ProfileFormProps {
  user: User
  onProfileUpdated: (user?: User) => void
  onCancel: () => void
}

interface ProfileFields {
  name: string
  mobile: string
}

export const ProfileForm: React.FunctionComponent<ProfileFormProps> = ({ user, onProfileUpdated, onCancel }) => {
  const { t } = useTranslation()
  const { handleError, flash } = useContext(AppContext)
  const [session] = useContext(SessionContext)
  const { control, handleSubmit } = useForm<ProfileFields>({
    defaultValues: user,
    mode: 'onBlur',
  })

  const onSubmit = (values: User) => {
    Api.request<User>({
      method: 'PATCH',
      url: `/users/${user.id}`,
      data: lodash.omitBy(values, lodash.isEmpty),
    })
      .then(res => {
        flash(t('profile.profile_updated'))
        Object.assign(session.user, res.data)
        onProfileUpdated(session.user || undefined)
      })
      .catch(handleError)
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} autoComplete="off">
      <FormControl margin="normal" fullWidth>
        <Controller
          control={control}
          name="name"
          render={({ value, onChange }) => <TextField autoFocus label={t('user.name')} type="text" value={value} onChange={onChange} />}
          rules={{
            required: { value: true, message: t('errors.required') },
          }}
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <Controller
          control={control}
          name="mobile"
          render={({ value, onChange }) => <TextField label={t('user.mobile')} type="text" value={value} onChange={onChange} />}
          // rules={{
          //   required: { value: true, message: t('errors.required') },
          // }}
        />
      </FormControl>

      <FormControl margin="normal" fullWidth>
        <Box display="flex">
          <Button variant="contained" type="submit" color="primary">
            {t('common.save')}
          </Button>
          <div style={{ width: 10 }} />
          <Button variant="contained" onClick={onCancel}>
            {t('common.cancel')}
          </Button>
        </Box>
      </FormControl>
    </form>
  )
}
