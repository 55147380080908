import i18next from 'i18next'
import { initReactI18next } from 'react-i18next'

import id from '../locales/id.json'
import FormatHelper from './FormatHelper'

export type ConvertedToObjectType<T> = {
  [P in keyof T]: T[P] extends string ? string : ConvertedToObjectType<T[P]>
}

export type TranslationResource = typeof id
export type LanguageKey = keyof TranslationResource

export const translations: ConvertedToObjectType<TranslationResource> = {} as any

/*
 * Converts the static JSON file into an object where keys are identical
 * but values are strings concatenated according to syntax.
 * This is helpful when using the JSON file keys and still have the intellisense support
 * along with type-safety
 */
const convertLanguageJsonToObject = (obj: any, dict: any, current?: string) => {
  Object.keys(obj).forEach(key => {
    const currentLookupKey = current ? `${current}.${key}` : key
    if (typeof obj[key] === 'object') {
      dict[key] = {}
      convertLanguageJsonToObject(obj[key], dict[key], currentLookupKey)
    } else {
      dict[key] = currentLookupKey
    }
  })
}

i18next.languages = ['id']
export const i18n = i18next
  // pass the i18n instance to react-i18next.
  .use(initReactI18next)
  // detect user language
  // learn more: https://github.com/i18next/i18next-browser-languageDetector
  // .use(LanguageDetector)
  // init i18next
  // for all options read: https://www.i18next.com/overview/configuration-options
  .init(
    {
      resources: {
        id,
      },
      lng: 'id',
      fallbackLng: 'id',
      // nsSeparator: '.',
      // debug:
      //   process.env.NODE_ENV !== 'production' &&
      //   process.env.NODE_ENV !== 'test',
      debug: false,

      interpolation: {
        escapeValue: false, // not needed for react as it escapes by default
        format: (value, format, lng) => {
          if (format === 'uppercase') return value.toUpperCase()
          if (format === 'amount') return FormatHelper.amount(value, 'default')
          // if(value instanceof Date) return moment(value).format(format);
          return value
        },
      },
      appendNamespaceToMissingKey: false,
      parseMissingKeyHandler: k => `{${k}}`,
    },
    // () => {
    //   convertLanguageJsonToObject(en, translations)
    // }
  )
