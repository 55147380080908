import dayjs from 'dayjs'
import { Address } from './Address'
import { Country } from './Country'
import { Files } from './Files'
import { User } from './User'

export enum EUserDetailStatus {
  DRAFTED = 'drafted',
  REVIEW = 'review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum EUserDetailIdentityType {
  KTP = 'KTP',
  NPWP = 'NPWP',
  PASSPORT = 'PASSPORT',
}

export enum EUserDetailGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum EUserDetailMaritalStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  WIDOW = 'widow',
  WIDOWER = 'widower',
}

export enum EUserDetailPEPStatus {
    LOW = 'low',
    MEDIUM = 'medium',
    HIGH = 'high'
  }

export class UserDetail {
  constructor(
    public id: string,
    public createdTime: string,
    public userId: string,
    public updatedTime?: string,
    public gender?: EUserDetailGender,
    public identityType?: EUserDetailIdentityType,
    public identityNumber?: string,
    public cityOfBirth?: string,
    public dateOfBirth?: Date,
    public jobTitle?: string,
    public maritalStatus?: EUserDetailMaritalStatus,
    public identityAddressId?: string,
    public currentAddressId?: string,
    public identityFileId?: string,
    public selfieFileId?: string,
    public npwpFileId?: string,
    public nationality?: string,
    public status?: EUserDetailStatus,
    public eddFileId?: string,
    public notes?: string,
    public pepStatus?: EUserDetailPEPStatus,
    public user?: User,
    public identityAddress?: Address,
    public currentAddress?: Address,
    public identityFile?: Files,
    public selfieFile?: Files,
    public npwpFile?: Files,
    public eddFile?: Files,
  ) {}
}

export class UserDetailPayload {
  constructor(public userDetail: Partial<UserDetail>, public address: Partial<Address>, public user: Partial<User>, public currentAddress?: Partial<Address>) {}
}
