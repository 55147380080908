import { Avatar, Box, Divider, Drawer, Hidden, List, ListItem, Typography, makeStyles } from '@material-ui/core'
import { DashboardRounded, Toc } from '@material-ui/icons'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useLocation } from 'react-router-dom'
import NavItem from './NavItem'

type NavBarProps = {
  onMobileClose?: (...args: any[]) => any
  openMobile?: boolean
}

const AdminNavBar: React.SFC<NavBarProps> = ({ onMobileClose, openMobile }) => {
  const { t } = useTranslation()
  const classes = useStyles()
  const location = useLocation()

  const [session] = useContext(SessionContext)
  const { user } = session

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose()
    }
  }, [location.pathname])

  if (!session.user) return null

  const content = (
    <Box flexDirection="column">
      <Box alignItems="center" display="flex" flexDirection="column" p={2} pb={0}>
        <Avatar className={classes.avatar} component={RouterLink} to="/admin/profile" />
        <Typography
          className={classes.name}
          color="textPrimary"
          variant="body2"
          gutterBottom
          style={{ paddingLeft: 10, paddingRight: 10, textOverflow: 'clip' }}
        >
          {user?.email}
        </Typography>

        <Typography color="textSecondary" variant="body1" gutterBottom>
          {user?.name}
        </Typography>
      </Box>
      <Box p={2}>
        <List>
          <NavTitle title={t('admin.monitoring')} />
          <>
            <NavItem href="/admin/withdraws" title={t('admin.withdraws')} icon="/static/images/icons/withdraw.svg" />
            <NavItem href="/admin/depositrequests" title={t('admin.deposit_requests')} icon="/static/images/icons/payment.svg" />
            <NavItem href="/admin/depositrequeststransfers" title={t('admin.deposit_requests_transfer_only')} icon="/static/images/icons/deposit.svg" />
          </>
          <NavTitle title={t('admin.reporting')} />
          <>
            <NavItem href="/admin/settlements" title={t('admin.settlements')} icon="/static/images/icons/settled.svg" />
            <NavItem href="/admin/releasebalances" title={t('admin.release_balances')} icon="/static/images/icons/settled.svg" />
            <NavItem href="/admin/callbacks" title={t('admin.callbacks')} icon="/static/images/icons/api.svg" />
            <NavItem href="/admin/suspectedtransactions" title={t('admin.suspected_trx')} icon="/static/images/icons/suspected_transaction.svg" />
            <NavItem href="/admin/highrisktransactions" title={t('admin.highrisk_trx')} icon="/static/images/icons/highrisk_transaction.svg" />
            <NavItem href="/admin/audittrail" title={t('admin.audit_trail')} icon={"/static/images/icons/audit_trail.svg"} />
          </>
          <NavTitle title={t('admin.management')} />
          <>
            <NavItem href="/admin/partners" title={t('admin.partners')} icon="/static/images/icons/merchants.svg" />
            <NavItem href="/admin/fees" title={t('admin.fees')} icon="/static/images/icons/fees.svg" />
            <NavItem href="/admin/partnernetwork" title={t('admin.partner_network')} icon="/static/images/icons/banks.svg" />
          </>
          <>
            <NavItem href="/admin/dttot" title={t('admin.dttot')} icon="/static/images/icons/skull.svg" />
          </>
        </List>
      </Box>
      <Box flexGrow={1} />
    </Box>
  )
  return (
    <div className={classes.root}>
      <Hidden lgUp>
        <Drawer anchor="left" classes={{ paper: classes.mobileDrawer }} onClose={onMobileClose} open={openMobile} variant="temporary">
          {content}
        </Drawer>
      </Hidden>
      <Hidden mdDown>
        <Drawer anchor="left" classes={{ paper: classes.desktopDrawer }} open variant="persistent">
          {content}
        </Drawer>
      </Hidden>
    </div>
  )
}

const NavTitle = (props: { title: string; style?: any }) => (
  <div>
    <Divider style={{ marginBlock: 16, backgroundColor: 'transparent', ...props.style }} />
    <ListItem style={{ fontWeight: 'bold', paddingLeft: 10 }}>{props.title}</ListItem>
  </div>
)

AdminNavBar.defaultProps = {
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onMobileClose: () => {},
  openMobile: false,
}

const useStyles = makeStyles(() => ({
  root: {},
  mobileDrawer: {
    width: 256,
  },
  desktopDrawer: {
    backgroundColor: '#ffffffe6',
    width: 256,
    // paddingTop: 35,
    height: '100%',
  },
  avatar: {
    cursor: 'pointer',
    width: 64,
    height: 64,
    marginTop: 10,
    marginBottom: 10,
  },
  name: {},
}))

export default AdminNavBar
