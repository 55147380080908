import { Transaction, Account } from './'

export enum DepositStatus {
  PENDING = 'pending',
  PROCESS = 'process',
  FAILED = 'failed',
  SUCCEED = 'succeed',
}

export class Deposit {
  constructor(
    public id: string,
    public createdTime: Date,
    public updatedTime: Date,
    public accountId: string,
    public transactionId: number,
    public status: DepositStatus,
    public amount: number,
    public notes: string,
    public networkId: string,
    public reference?: string,
    public referenceData?: Map<string, string>,
    public account?: Account,
    public transaction?: Transaction,
  ) {}
}
