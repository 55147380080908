import axios from 'axios'
import Env from './Env'

const Api = axios.create({
  baseURL: Env.apiUrl,
  timeout: 60000,
  // withCredentials: true
})

Api.interceptors.response.use(
  (response) => {
    // Any status code that lie within the range of 2xx cause this function to trigger
    // Do something with response data
    return response
  },
  (error) => {
    // Any status codes that falls outside the range of 2xx cause this function to trigger
    // Do something with response error
    // console.error('API Error', error, error.response)
    if (
      error &&
      error.response &&
      error.response.status === 401 &&
      error.response.data &&
      error.response.data.error &&
      error.response.data.error.name === 'UnauthorizedError' &&
      error.response.data.error.message &&
      (error.response.data.error.message === 'Error verifying token' ||
      error.response.data.error.message.search('expire') !== -1) &&
      window.location.pathname.indexOf('/auth') !== 0
    ) {
      delete Api.defaults.headers.common['Authorization']
      sessionStorage.clear()
      window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
    }
    return Promise.reject(error)
  }
)

Api.defaults.baseURL = Env.apiUrl

export default Api
