import { AppBar, Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Hidden, IconButton, Slide, Toolbar, Tooltip, useScrollTrigger } from '@material-ui/core'
import { Theme, alpha, makeStyles } from '@material-ui/core/styles'
import InputIcon from '@material-ui/icons/Input'
import MenuIcon from '@material-ui/icons/Menu'
import clsx from 'clsx'
import Logo from 'components/Logo'
import { SessionContext } from 'contexts/SessionContext'
import React, { useContext, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Link as RouterLink, useHistory } from 'react-router-dom'

type TopBarProps = {
  className?: string
  onMobileNavOpen?: (...args: any[]) => any
}

const HideOnScroll = (props: any) => {
  const { children } = props
  const trigger = useScrollTrigger()

  return (
    <Slide appear={false} direction="down" in={!trigger}>
      {children}
    </Slide>
  )
}

const TopBar: React.FunctionComponent<TopBarProps> = ({ className, onMobileNavOpen, ...rest }) => {
  const history = useHistory()
  const [isConfirmSignout, setIsConfirmLogout] = useState(false)
  const [session, sessionDispatch] = useContext(SessionContext)
  const classes = useStyles()
  const [notifications] = useState([])
  const [searchValue, setSearchValue] = useState('')
  const { t } = useTranslation()

  const onSignout = () => {
    history.replace('/auth/signout')
    // sessionDispatch({ type: 'SIGNOUT' })
    // window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
    // Api.request({
    //   method: 'POST',
    //   url: '/users/logout'
    // }).then(() => {
    //   sessionDispatch({ type: 'SIGNOUT' })
    //   window.location.replace(process.env.PUBLIC_URL + '/auth/signin')
    // })
  }

  const onEnterPress = (event: any) => {
    if (event.key === 'Enter' && searchValue !== '') {
      history.push(`/admin/cs/transactionid/${searchValue}`)
    }
  }

  return (
    <HideOnScroll>
      <AppBar
        className={clsx(classes.root, className)}
        elevation={0}
        // position="fixed"
        // position="absolute"
        // color="default"
        {...rest}
      >
        <Toolbar>
          <RouterLink to="/admin">
            <Logo variant="dark" />
          </RouterLink>

          <Box flexGrow={1} />

          <Tooltip title={t('signout.tooltip') as string}>
            <IconButton color="inherit" onClick={() => setIsConfirmLogout(true)}>
              <InputIcon />
            </IconButton>
          </Tooltip>
          <Hidden lgUp>
            <IconButton color="inherit" onClick={onMobileNavOpen}>
              <MenuIcon />
            </IconButton>
          </Hidden>
          
          <Dialog
            open={isConfirmSignout}
            fullWidth
            onClose={() => setIsConfirmLogout(false)}
          >
            <DialogTitle>{t('signout.dialog.title')}</DialogTitle>
            <DialogContent>{t('signout.dialog.content')}</DialogContent>
            <DialogActions>
              <Button variant="outlined" onClick={() => setIsConfirmLogout(false)}>{t('common.cancel')}</Button>
              <Button variant="contained" color="primary" onClick={onSignout}>{t('signout.dialog.agree')}</Button>
            </DialogActions>
          </Dialog>
        </Toolbar>
      </AppBar>
    </HideOnScroll>
  )
}

const useStyles = makeStyles((theme: Theme) => ({
  root: {
    backgroundColor: 'transparent',
  },
  avatar: {
    width: 60,
    height: 60,
  },

  search: {
    position: 'relative',
    borderRadius: theme.shape.borderRadius,
    backgroundColor: alpha(theme.palette.common.white, 0.15),
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.white, 0.25),
    },
    marginLeft: 0,
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
      width: 'auto',
    },
  },
  searchIcon: {
    padding: theme.spacing(0, 2),
    height: '100%',
    position: 'absolute',
    pointerEvents: 'none',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  inputRoot: {
    color: 'inherit',
  },
  inputInput: {
    padding: theme.spacing(1, 1, 1, 0),
    // vertical padding + font size from searchIcon
    paddingLeft: `calc(1em + ${theme.spacing(4)}px)`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '12ch',
      '&:focus': {
        width: '28ch',
      },
    },
  },
}))

export default TopBar
