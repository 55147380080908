import { Files } from './Files'
import { Merchant } from './Merchant'
import { Partner } from './Partner'
import { Address } from './Address'

export enum EPartnerOwnerStatus {
  DRAFTED = 'drafted',
  REVIEW = 'review',
  APPROVED = 'approved',
  REJECTED = 'rejected',
}

export enum EPartnerOwnerIdentityType {
  KTP = 'KTP',
  NPWP = 'NPWP',
  PASSPORT = 'PASSPORT',
}

export enum EPartnerOwnerGender {
  MALE = 'male',
  FEMALE = 'female',
}

export enum EPartnerOwnerMaritalStatus {
  SINGLE = 'single',
  MARRIED = 'married',
  WIDOW = 'widow',
  WIDOWER = 'widower',
}

export enum EPartnerOwnerPEPStatus {
  LOW = 'low',
  MEDIUM = 'medium',
  HIGH = 'high',
}

export class PartnerOwner {
  constructor(
    public id: string,
    public createdTime: string,
    public updatedTime?: string,
    public partnerId?: string,
    public name?: string,
    public gender?: EPartnerOwnerGender,
    public mobile?: string,
    public identityType?: EPartnerOwnerIdentityType,
    public identityNumber?: string,
    public cityOfBirth?: string,
    public dateOfBirth?: string,
    public jobTitle?: string,
    public maritalStatus?: EPartnerOwnerMaritalStatus,
    public identityAddressId?: string,
    public currentAddressId?: string,
    public identityFileId?: string,
    public npwpFileId?: string,
    public nationality?: string,
    public status?: EPartnerOwnerStatus,
    public eddFileId?: string,
    public pepStatus?: EPartnerOwnerPEPStatus,
    public notes?: string,
    public partner?: Partner,
    public identityAddress?: Address,
    public currentAddress?: Address,
    public identityFile?: Files,
    public npwpFile?: Files,
    public eddFile?: Files,
  ) {}
}
