export interface DocumentInterface {
  doc_type: string
  doc_number: string
  doc_expire: null | Date
  upload: null | File
  metadata?: any
}

export class Document implements DocumentInterface {
  public doc_type: string
  public doc_number: string
  public doc_expire: null | Date
  public upload: null | File
  public metadata?: any

  constructor(document: DocumentInterface) {
    this.doc_type = document.doc_type
    this.doc_number = document.doc_number
    this.doc_expire = document.doc_expire
    this.upload = document.upload
    this.metadata = document.metadata
  }
}
