import React from 'react'
import { darken, makeStyles, Theme } from '@material-ui/core'
import classNames from 'classnames'

export const Decoration = () => {
  const classes = useStyles()

  return (
    <div className={classes.bgWrap}>
      <div
        className={classNames(
          classes.bgbar,
          // horizontalMenu && classes.highTop,
          // gradient ? classes.gradientBg : classes.solidBg,
          // getBgPosition(bgPosition),
          classes.gradientBg,
          classes.halfBg,
        )}
      >
        {/* <PetalBg className={classes.decorationWave} color="#fffs" {...props} /> */}
        {/* <img
          alt="Decoration"
          src={petalBg}
          className={classes.decorationWave}
          {...props}
        /> */}

        <img
          className={classes.decorationWave}
          src="data:image/svg+xml;base64,PD94bWwgdmVyc2lvbj0iMS4wIiBlbmNvZGluZz0idXRmLTgiPz4KPCEtLSBHZW5lcmF0b3I6IEFkb2JlIElsbHVzdHJhdG9yIDIyLjAuMCwgU1ZHIEV4cG9ydCBQbHVnLUluIC4gU1ZHIFZlcnNpb246IDYuMDAgQnVpbGQgMCkgIC0tPgo8c3ZnIHZlcnNpb249IjEuMSIgaWQ9IkxheWVyXzEiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgeG1sbnM6eGxpbms9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkveGxpbmsiIHg9IjBweCIgeT0iMHB4IgoJIHZpZXdCb3g9IjAgMCAzMDAgNDcuMSIgc3R5bGU9ImVuYWJsZS1iYWNrZ3JvdW5kOm5ldyAwIDAgMzAwIDQ3LjE7IiB4bWw6c3BhY2U9InByZXNlcnZlIj4KPHN0eWxlIHR5cGU9InRleHQvY3NzIj4KCS5zdDB7ZmlsbDojRkFGQUZBO30KPC9zdHlsZT4KPHBhdGggY2xhc3M9InN0MCIgZD0iTTMwMCw0Ni45TDAsNDcuMVY4LjljMCwwLDIxLjEsMTQuMyw2NS4yLDE0LjFjNDAuNi0wLjIsNzYuNC0yMywxMjgtMjNDMjQzLjMsMCwzMDAsMTYuNCwzMDAsMTYuNFY0Ni45eiIvPgo8L3N2Zz4K"
          alt="decoration"
        />
      </div>
    </div>
  )
}

export const gradientBgLight = (theme: Theme) =>
  `linear-gradient(-45deg, ${theme.palette.primary.main} 0%, ${theme.palette.primary.main} 33%, ${theme.palette.secondary.main} 100%);`
export const gradientBgDark = (theme: Theme) =>
  `linear-gradient(-45deg, ${darken(theme.palette.primary.main, 0.4)} 0%, ${darken(theme.palette.primary.main, 0.4)} 33%, ${darken(
    theme.palette.secondary.main,
    0.4,
  )} 100%);`

export const gradientOceanLight = (theme: Theme) => `linear-gradient(-45deg, #2196F3 0%, #2196F3 33%, #00BFA5 100%)`

const useStyles = makeStyles(theme => ({
  bgWrap: {
    position: 'fixed',
    background: theme.palette.background.default,
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
  },
  halfBg: {},
  fullBg: {},
  bgbar: {
    backgroundAttachment: 'fixed',
    width: '100%',
    top: 0,
    left: 0,
    position: 'absolute',
    // '&$headerBg': {
    //   height: 64
    // },
    '&$halfBg': {
      height: 400,
    },
    '&$fullBg': {
      height: '100%',
    },
  },
  gradientBg: {
    backgroundImage: theme.palette.type === 'dark' ? gradientBgDark(theme) : gradientOceanLight(theme),
    backgroundAttachment: 'fixed',
  },
  solidBg: {
    backgroundColor: theme.palette.type === 'dark' ? darken(theme.palette.primary.main, 0.4) : theme.palette.primary.main,
  },
  decorationWave: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    left: -2,
    bottom: -32,
    transform: 'scale(1.1,0.8)',
    transformOrigin: 'bottom',
    [theme.breakpoints.down('xs')]: {
      display: 'none',
    },
  },
  decorationPetal: {
    width: '100%',
    height: 'auto',
    position: 'absolute',
    left: 0,
    top: 0,
  },
}))
