export class Bank {
  constructor(
    public id: string,
    public networkType: string,
    public networkId: string,
    public name: string,
    public fullName: string,
    public swiftCode: string,
    public country: string,
    public currency: string,
  ) {}
}
