import { VAccountPartner } from "models";
import { WithdrawStatus } from "./Withdraw";

export class TabTransaction {
  constructor(
    public id: string,
    public transactionId: string,
    public createdTime: Date,
    public updatedTime: Date,
    public accountId: string,
    public merchantId: string,
    public senderName: string,
    public senderCity: string,
    public receiverBank: string,
    public receiverAccount: string,
    public receiverName: string,
    public receiverCity: string,
    public amount: number,
    public feeAmount: number,
    public disburseAmount: number,
    public notes: string,
    public beneficiaryId: string,
    public reference: string,
    public status: WithdrawStatus,
    public statusMessage: string,
    public requestId: string,
    public networkCode: string,
    public account: VAccountPartner,
  ) {}
}
