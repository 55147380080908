const _env = window.process && window.process.env ? window.process.env : process.env

const Env = {
  apiUrl: _env.REACT_APP_API_URL,
  partnerId: _env.REACT_APP_PARTNER_ID,
  webUrl: _env.REACT_APP_WEB_URL,
  accountRefreshInterval: Number(_env.REACT_APP_ACCOUNT_REFRESH === undefined ? 10 : _env.REACT_APP_ACCOUNT_REFRESH) * 1000,
  recaptchaKey: _env.REACT_APP_RECAPTCHA_KEY || '',
}

export default Env
