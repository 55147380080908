import { AccountBalance, Partner, UserDetail } from './'

export enum UserStatus {
  Active = 'active',
  Inactive = 'inactive',
}

export enum UserRoles {
  Admin = 'admin',
  AdminFinance = 'adminfinance',
  OperationFinance = 'operationfinance',
  OperationSupport = 'operationsupport',
  Compliance = 'compliance',
  SUPERADMIN = 'superadmin',
	ADMIN = 'admin',
	FINANCE = 'finance',
	OPERATION = 'operationsupport',
}

export class User {
  constructor(
    public id: string | undefined,
    public createdTime: Date,
    public updatedTime: Date,
    public status: UserStatus,
    public email: string,
    public mobile: string,
    public name: string,
    public partnerId: string,
    public roles: UserRoles[],
    public twoFAEnabled: boolean,
    public configs: { [k: string]: any },
    public partner?: Partner,
    public accounts?: UserAccount[],
    public userDetail?: UserDetail
  ) {}

  public static create(args: Partial<User> & Required<Pick<User, 'email' | 'mobile' | 'name' | 'partnerId'>>) {
    return new User(
      args.id,
      args.createdTime ?? new Date(),
      args.updatedTime ?? new Date(),
      args.status ?? UserStatus.Inactive,
      args.email,
      args.mobile,
      args.name,
      args.partnerId,
      args.roles ?? [UserRoles.OPERATION],
      Boolean(args.twoFAEnabled),
      args.configs ?? {},
      args.partner,
    )
  }

  public hasRole: (role: string) => boolean = role => {
    return this.roles.findIndex(r => r === role) !== -1
  }
}

export class UserAccount {
  constructor(
    public id: string,
    public createdTime: string,
    public updatedTime: string,
    public userId: string,
    public currencyId: string,
    public number: string,
    public balance?: AccountBalance,
  ) {}
}
