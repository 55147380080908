export enum NetworkTypes {
  Bank = 'bank',
  Switching = 'switching',
  Crypto = 'crypto',
}

export class Network {
  constructor(
    public id: string,
    public name: string,
    public type: NetworkTypes,
    public symbol: string,
    public status: string,
    public accounts?: NetworkAccount[],
  ) {}
}

export class NetworkAccount {
  constructor(
    public id: number,
    public updatedTime: Date,
    public networkId: string,
    public currency: string,
    public accountNo: string,
    public accountName: string,
    public balance: number,
  ) {}
}
